<template>
  <el-row class="basmsg" v-if="dataInfo">
    <el-col :span="24" class="divs">集中开票单号：{{ serial_number }}</el-col>
    <el-col :span="24" class="divs">购买方名称：{{ dataInfo.buyer_company_name }}</el-col>
    <el-col :span="24" class="divs">统一社会信用代码：{{ dataInfo.buyer_company_code }}</el-col>

    <el-col :span="8" class="divs">
      可开票金额：
      <span style="font-weight:bold;color:#f74848;font-size:18px">
        {{ dataInfo.not_invoice_amount | formatMoney }} (大写:{{
          dataInfo.not_invoice_amount | dealBigMoney1
        }})
      </span>
    </el-col>
    <el-col :span="8" class="divs">
      未收票金额：
      <span style="font-weight:bold;color:#f74848;font-size:18px">
        {{ dataInfo.not_invoiced_amount | formatMoney }}(大写:{{
          dataInfo.not_invoiced_amount | dealBigMoney1
        }})
      </span>
    </el-col>
    <el-col :span="8" class="divs">
      累计开票金额：
      <span style="font-weight:bold;color:#f74848;font-size:18px">
        {{ dataInfo.order_amount | formatMoney }}(大写:{{ dataInfo.order_amount | dealBigMoney1 }})
      </span>
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: "",
  components: {},
  props: {
    dataInfo: {
      type: Object,
      default: null,
    },
  },
  created() {
    if (this.dataInfo.apply_serial_number) {
      this.serial_number = this.dataInfo.apply_serial_number;
    } else {
      this.getNumber();
    }
  },
  mounted() {},
  data() {
    return {
      serial_number: null,
    };
  },
  watch: {},
  computed: {},
  methods: {
    getNumber() {
      //获取编号
      this.$axios
        .get("/api/rule/get_rule_number", {
          app_modular: "sc_invoice",
        })
        .then(res => {
          console.log(res);
          if (res.code == 200) {
            if (res.data) {
              this.serial_number = res.data.num[0];
            }
          }
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.basmsg {
  position: relative;
  background-color: #fafafa;
  padding: 15px 25px;
  box-sizing: border-box;
  margin-bottom: 15px;
  .el-col {
    margin-bottom: 10px;
  }
  .divs {
    min-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .showStatus {
    position: absolute;
    right: 25px;
    top: 25px;
  }
}
.colors2 {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 5px;
  border-radius: 50%;
}
</style>
