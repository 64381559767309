<template>
  <el-dialog
    width="500px"
    :visible.sync="visible"
    title="编辑购货单位"
    :close-on-click-modal="false"
    :show-close="false"
    top="30vh"
  >
    <el-row class="opinion-box">
      <el-col :span="6" style="margin-bottom:12px">
        购货单位名称
      </el-col>
      <el-col :span="16" :offset="2" style="margin-bottom:12px">
        <el-input v-model="buyer.name" maxlength="32" show-word-limit style="width: 80%"></el-input>
      </el-col>
      <el-col :span="6" style="margin-bottom:12px">
        纳税人识别号
      </el-col>
      <el-col :span="16" :offset="2" style="margin-bottom:12px">
        <el-input v-model="buyer.code" maxlength="32" show-word-limit style="width: 80%"></el-input>
      </el-col>
      <el-col :span="6" style="margin-bottom:12px">
        地址
      </el-col>
      <el-col :span="16" :offset="2" style="margin-bottom:12px">
        <el-input
          v-model="buyer.address"
          maxlength="32"
          show-word-limit
          style="width: 80%"
        ></el-input>
      </el-col>
      <el-col :span="6" style="margin-bottom:12px">
        电话
      </el-col>
      <el-col :span="16" :offset="2" style="margin-bottom:12px">
        <el-input v-model="buyer.tel" maxlength="32" show-word-limit style="width: 80%"></el-input>
      </el-col>
      <el-col :span="6" style="margin-bottom:12px">
        开户行
      </el-col>
      <el-col :span="16" :offset="2" style="margin-bottom:12px">
        <el-input v-model="buyer.bank" maxlength="32" show-word-limit style="width: 80%"></el-input>
      </el-col>
      <el-col :span="6" style="margin-bottom:12px">
        账号
      </el-col>
      <el-col :span="16" :offset="2" style="margin-bottom:12px">
        <el-input
          v-model="buyer.account"
          maxlength="32"
          show-word-limit
          style="width: 80%"
        ></el-input>
      </el-col>
    </el-row>
    <div class="footer-box">
      <el-button size="small" class="footer-btn" @click="visible = false">取消</el-button>
      <el-button size="small" type="primary" class="footer-btn" @click="confirmBtn">
        确定
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "",
  components: {},
  props: {},
  created() {},
  mounted() {},
  data() {
    return {
      visible: false,
      callback: null,
      buyer: {
        name: "",
        code: "",
        address: "",
        tel: "",
        account: "",
        bank: "",
      },
    };
  },
  watch: {},
  computed: {},
  methods: {
    open(val, callback) {
      this.buyer = JSON.parse(JSON.stringify(val.buyer));
      this.callback = callback;
      this.visible = true;
    },
    confirmBtn() {
      let buyer = this.buyer;
      this.callback(buyer);
      this.visible = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.opinion-box {
  padding: 20px;
}
/deep/.el-dialog__header {
  padding-bottom: 0px;
}
/deep/.el-dialog__body {
  padding-top: 10px;
}
.footer-box {
  margin-top: 15px;
  text-align: center;

  .footer-btn {
    width: 80px;
  }
}
.remark {
  color: #f56c6c;
}
</style>
