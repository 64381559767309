var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"width":"1200px","margin":"0 auto"},attrs:{"id":"main_MyOrder_Invoice"}},[_c('el-container',{staticClass:"head_info_bar",staticStyle:{"height":"100%"}},[_c('el-header',{attrs:{"height":"auto"}},[_c('fw-header',{attrs:{"titles":"线下开票"}})],1),(_vm.dataInfo)?_c('el-main',[_c('div',{staticStyle:{"padding":"10px 15px"}},[_c('fw-title',{staticStyle:{"padding-top":"15px"},attrs:{"titles":"基本信息"}}),_c('basmsg',{attrs:{"dataInfo":_vm.dataInfo}}),_c('el-row',[_c('el-col',{attrs:{"span":12}},[_c('fw-title',{staticStyle:{"padding-top":"15px"},attrs:{"titles":"购货单位"}}),_c('div',{staticClass:"bar"},[_c('div',{staticClass:"bar_col",staticStyle:{"align-items":"flex-start"}},[_c('div',{staticClass:"divs"},[_vm._v(" 购货单位 ")]),_c('div',[_c('div',{staticClass:"choseGongji buyer_box"},[_c('h4',{staticClass:"ellipsisText ",attrs:{"title":_vm.bankAccInfo.buyer.name || '--'}},[_vm._v(" "+_vm._s(_vm.bankAccInfo.buyer.name || "--")+" ")]),_c('div',[_c('div',[_c('p',[_vm._v("纳税人识别号")]),_c('p',[_vm._v("地址")]),_c('p',[_vm._v("电话")]),_c('p',[_vm._v("开户行及账号")])]),_c('div',[_c('p',{staticClass:"ellipsisText ",attrs:{"title":_vm.bankAccInfo.buyer.code || '--'}},[_vm._v(" "+_vm._s(_vm.bankAccInfo.buyer.code)+" ")]),_c('p',{staticClass:"ellipsisText ",attrs:{"title":_vm.bankAccInfo.buyer.address || '--'}},[_vm._v(" "+_vm._s(_vm.bankAccInfo.buyer.address || "--")+" ")]),_c('p',{staticClass:"ellipsisText ",attrs:{"title":_vm.bankAccInfo.buyer.tel || '--'}},[_vm._v(" "+_vm._s(_vm.bankAccInfo.buyer.tel || "--")+" ")]),_c('p',{staticClass:"ellipsisText ",attrs:{"title":((_vm.bankAccInfo.buyer.bank || '--') + " / " + (_vm.bankAccInfo.buyer.account ||
                              '--'))}},[_vm._v(" "+_vm._s(((_vm.bankAccInfo.buyer.bank || "--") + " / " + (_vm.bankAccInfo.buyer.account || "--")))+" ")])])]),_c('i',{staticClass:"el-icon-edit",on:{"click":_vm.editBuyer}})])])])])],1),_c('el-col',{attrs:{"span":12}},[_c('fw-title',{staticStyle:{"padding-top":"15px"},attrs:{"titles":"销售单位"}}),_c('div',{staticClass:"bar"},[_c('div',{staticClass:"bar_col",staticStyle:{"align-items":"flex-start"}},[_c('div',{staticClass:"divs"},[_vm._v(" 销售单位 ")]),_c('div',[_c('div',{staticClass:"choseGongji"},[_c('h4',{staticClass:"ellipsisText ",attrs:{"title":_vm.bankAccInfo.seller.name || '--'}},[_vm._v(" "+_vm._s(_vm.bankAccInfo.seller.name || "--")+" ")]),_c('div',[_c('div',[_c('p',[_vm._v("纳税人识别号")]),_c('p',[_vm._v("地址")]),_c('p',[_vm._v("电话")]),_c('p',[_vm._v("开户行及账号")])]),_c('div',[_c('p',{staticClass:"ellipsisText ",attrs:{"title":_vm.bankAccInfo.seller.code || '--'}},[_vm._v(" "+_vm._s(_vm.bankAccInfo.seller.code || "--")+" ")]),_c('p',{staticClass:"ellipsisText ",attrs:{"title":_vm.bankAccInfo.seller.address || '--'}},[_vm._v(" "+_vm._s(_vm.bankAccInfo.seller.address || "--")+" ")]),_c('p',{staticClass:"ellipsisText ",attrs:{"title":_vm.bankAccInfo.seller.tel || '--'}},[_vm._v(" "+_vm._s(_vm.bankAccInfo.seller.tel || "--")+" ")]),_c('p',{staticClass:"ellipsisText ",attrs:{"title":((_vm.bankAccInfo.seller.bank || '--') + " / " + (_vm.bankAccInfo.seller.account ||
                              '--'))}},[_vm._v(" "+_vm._s(((_vm.bankAccInfo.seller.bank || "--") + " / " + (_vm.bankAccInfo.seller.account || "--")))+" ")])])])])])])])],1)],1),_c('el-row',[_c('fw-title',{staticStyle:{"padding-top":"15px"},attrs:{"titles":"联系地址"}}),(_vm.showAddTable)?_c('el-col',{staticStyle:{"margin-top":"15px"},attrs:{"span":18}},[_c('vxe-table',{ref:"xTable1",staticClass:"mytable-scrollbar",staticStyle:{"min-height":"10vh"},attrs:{"stripe":"","highlight-hover-row":"","size":"small","border":false,"id":"toolbar_demo3","data":_vm.addressArr,"row-key":"","auto-resize":""}},[_c('vxe-column',{attrs:{"min-width":"180","fixed":"left"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('p',[_vm._v("类型")])]},proxy:true},{key:"default",fn:function(ref){
                              var row = ref.row;
return [_c('p',{staticClass:"ellipsisText",attrs:{"title":row.type || '--'}},[_vm._v(" "+_vm._s(row.type || "--")+" ")])]}}],null,false,2611777636)}),_c('vxe-column',{attrs:{"min-width":"140"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('p',[_vm._v(" 联系人 ")])]},proxy:true},{key:"default",fn:function(ref){
                              var row = ref.row;
return [(row.is_input)?[_c('el-input',{attrs:{"placeholder":"请输入","size":"small"},model:{value:(row.name),callback:function ($$v) {_vm.$set(row, "name", $$v)},expression:"row.name"}})]:[_vm._v(" "+_vm._s(row.name || "--")+" ")]]}}],null,false,3261034340)}),_c('vxe-column',{attrs:{"min-width":"260"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('p',[_vm._v(" 联系地址 ")])]},proxy:true},{key:"default",fn:function(ref){
                              var row = ref.row;
return [(row.is_input)?[_c('fw-map',{attrs:{"change":true,"address":row.address,"onlyAddress":false,"showInput":true},on:{"mapAddress":function (val) { return _vm.mapAddress(row, val); }}})]:[_vm._v(" "+_vm._s(_vm.renderAddress(row.address))+" ")]]}}],null,false,347913300)}),_c('vxe-column',{attrs:{"min-width":"140"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('p',[_vm._v(" 邮箱 ")])]},proxy:true},{key:"default",fn:function(ref){
                              var row = ref.row;
return [(row.is_input)?[_c('el-input',{attrs:{"placeholder":"请输入","size":"small"},model:{value:(row.email),callback:function ($$v) {_vm.$set(row, "email", $$v)},expression:"row.email"}})]:[_vm._v(" "+_vm._s(row.email || "--")+" ")]]}}],null,false,832975182)}),_c('vxe-column',{attrs:{"title":"操作","width":"140"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                              var row = ref.row;
                              var rowIndex = ref.rowIndex;
return [(row.is_input)?_c('div',{staticClass:"act-btn-box"},[_c('el-link',{staticClass:"act-btn",attrs:{"underline":false,"type":"primary"},on:{"click":function($event){return _vm.confirmBtn(row, rowIndex)}}},[_vm._v(" 确定 ")]),_c('el-link',{staticClass:"act-btn",attrs:{"underline":false,"type":"primary"},on:{"click":function($event){return _vm.cancelBtn(row, rowIndex)}}},[_vm._v(" 取消 ")])],1):_c('div',{staticClass:"act-btn-box"},[_c('el-link',{staticClass:"act-btn",attrs:{"underline":false,"type":"primary"},on:{"click":function($event){return _vm.editBtn(row)}}},[_vm._v(" 编辑 ")])],1)]}}],null,false,3556581920)})],1)],1):_vm._e()],1),_c('div',[_c('orderDetailTable',{attrs:{"tableList":_vm.tableList}})],1)],1)]):_vm._e(),_c('el-footer',{staticStyle:{"margin":"30px 0","height":"auto"}},[_c('el-row',[_c('el-col',{attrs:{"align":"center","span":24}},[_c('el-button',{staticStyle:{"width":"88px"},attrs:{"size":"small"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v("取消")]),_c('el-button',{staticStyle:{"width":"88px"},attrs:{"size":"small","type":"primary"},on:{"click":_vm.submit}},[_vm._v(" 提交 ")])],1)],1)],1)],1),_c('fw-choseticket',{ref:"fwchoseticket"}),_c('editBox',{ref:"editBox"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }