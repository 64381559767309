<template>
  <div style="margin:10px 0">
    <el-row>
      <el-col :span="24">
        <fw-title titles="订单明细" style="padding-top: 20px" />
      </el-col>
      <el-col :span="24" style="margin:15px">
        <div v-if="tableList.length > 0">
          <span style="color:#666;">本次申请开票金额 ：</span>
          <span style="color:#f74848;font-weight:bold;font-size:16px">
            {{ totalPrice | formatMoney }} (大写:{{ totalPrice | dealBigMoney1 }})
          </span>
        </div>
        <div v-else>&nbsp;</div>
      </el-col>
    </el-row>
    <div v-show="open">
      <vxe-table
        class="mytable-scrollbar"
        style="min-height:10vh"
        stripe
        highlight-hover-row
        size="small"
        id="toolbar_demo3"
        :row-config="{ height: 120 }"
        ref="xTable1"
        :data="tableList"
      >
        <vxe-column
          fixed="left"
          type="seq"
          title="序号"
          width="50"
          align="center"
          field="xuhao"
        ></vxe-column>
        <vxe-column min-width="160">
          <template #header>
            <p>订单编号</p>
            <p>订单业务状态</p>
          </template>
          <template #default="{ row }">
            <p class="ellipsisText" :title="row.serial_number">
              {{ row.serial_number || "--" }}
            </p>
            <el-tag type="warning" v-if="row.order_status == 10">待收单</el-tag>
            <el-tag type="warning" v-else-if="row.order_status == 20">
              待付款
            </el-tag>
            <el-tag type="success" v-else-if="row.order_status == 30">
              已付款
            </el-tag>
            <el-tag type="warning" v-else-if="row.order_status == 40">
              待发货
            </el-tag>
            <el-tag type="warning" v-else-if="row.order_status == 50">
              待收货
            </el-tag>
            <el-tag type="warning" v-else-if="row.order_status == 60">
              已收货
            </el-tag>
            <el-tag type="info" v-else-if="row.order_status == 70">
              已取消
            </el-tag>
            <el-tag type="danger" v-else-if="row.order_status == 80">
              已取消
            </el-tag>
            <el-tag type="danger" v-else-if="row.order_status == 90">
              已删除
            </el-tag>
            <p v-else>
              --
            </p>
          </template>
        </vxe-column>
        <vxe-column min-width="140">
          <template #header>
            <p>付款方式</p>
            <p>来源</p>
            <p>生效时间</p>
          </template>
          <template #default="{ row }">
            <p>
              <span style="background-color: #4387F8" class="colors"></span>
              <span style="color: #4387F8">
                {{ (row.order_model == 1 ? "先款后货" : "先货后款") || "--" }}
              </span>
            </p>
            <div>
              <template v-if="row.source == 1">
                自主新增
              </template>
              <template v-else-if="row.source == 2">
                企业购物车
              </template>
              <template v-else-if="row.source == 3">
                工业电商
              </template>
              <template v-else>
                --
              </template>
            </div>
            <p class="ellipsisText " :title="row.created_at || '--'">
              {{ row.created_at || "--" }}
            </p>
          </template>
        </vxe-column>
        <vxe-column min-width="140">
          <template #header>
            <p>采购方名称</p>
            <p>统一社会信用代码</p>
          </template>
          <template #default="{ row }">
            <p class="ellipsisText " :title="row.buyer_company_name || '--'">
              {{ row.buyer_company_name || "--" }}
            </p>
            <p class="ellipsisText " :title="row.buyer_company_code || '--'">
              {{ row.buyer_company_code || "--" }}
            </p>
          </template>
        </vxe-column>
        <vxe-column min-width="140">
          <template #header>
            <p>销售方名称</p>
            <p>统一社会信用代码</p>
          </template>
          <template #default="{ row }">
            <p class="ellipsisText " :title="row.seller_company_name || '--'">
              {{ row.seller_company_name || "--" }}
            </p>
            <p class="ellipsisText " :title="row.seller_company_code || '--'">
              {{ row.seller_company_code || "--" }}
            </p>
          </template>
        </vxe-column>
        <vxe-column min-width="140">
          <template #header>
            <p>订单总额</p>
            <p>付款金额</p>
            <p>发货金额</p>
            <p>收货金额</p>
          </template>
          <template #default="{ row }">
            <p class="ellipsisText" :title="row.order_amount">
              {{ row.order_amount | formatMoney }}
            </p>
            <p class="ellipsisText" :title="row.pay_amount">
              {{ row.pay_amount | formatMoney }}
            </p>
            <p class="ellipsisText" :title="row.send_amount">
              {{ row.send_amount | formatMoney }}
            </p>
            <p class="ellipsisText" :title="row.receipt_amount">
              {{ row.receipt_amount | formatMoney }}
            </p>
          </template>
        </vxe-column>
        <vxe-column min-width="140">
          <template #header>
            <p>开票金额</p>
          </template>
          <template #default="{ row }">
            <p class="ellipsisText" :title="row.not_invoice_amount">
              {{ row.not_invoice_amount | formatMoney }}
            </p>
          </template>
        </vxe-column>
      </vxe-table>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  components: {},
  props: {
    tableList: {
      type: Array,
      default: [],
    },
  },
  created() {},
  mounted() {},
  data() {
    return {
      open: true,
    };
  },
  watch: {},
  computed: {
    totalPrice() {
      let sum = this.tableList.reduce(function(total, item) {
        return total + Number(item.not_invoice_amount);
      }, 0);
      return sum;
    },
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.colors {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 5px;
  border-radius: 50%;
}
</style>
