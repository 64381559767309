<template>
  <div id="main_MyOrder_Invoice" style="width:1200px; margin:0 auto">
    <el-container style="height: 100%" class="head_info_bar">
      <el-header height="auto">
        <fw-header titles="线下开票" />
      </el-header>
      <el-main v-if="dataInfo">
        <div style="padding: 10px 15px">
          <fw-title titles="基本信息" style="padding-top: 15px" />
          <basmsg :dataInfo="dataInfo" />
          <el-row>
            <el-col :span="12">
              <fw-title titles="购货单位" style="padding-top: 15px" />

              <div class="bar">
                <div class="bar_col" style="align-items: flex-start;">
                  <div class="divs">
                    购货单位
                  </div>
                  <div>
                    <div class="choseGongji buyer_box">
                      <h4 class="ellipsisText " :title="bankAccInfo.buyer.name || '--'">
                        {{ bankAccInfo.buyer.name || "--" }}
                      </h4>
                      <div>
                        <div>
                          <p>纳税人识别号</p>
                          <p>地址</p>
                          <p>电话</p>
                          <p>开户行及账号</p>
                        </div>
                        <div>
                          <p class="ellipsisText " :title="bankAccInfo.buyer.code || '--'">
                            {{ bankAccInfo.buyer.code }}
                          </p>
                          <p class="ellipsisText " :title="bankAccInfo.buyer.address || '--'">
                            {{ bankAccInfo.buyer.address || "--" }}
                          </p>
                          <p class="ellipsisText " :title="bankAccInfo.buyer.tel || '--'">
                            {{ bankAccInfo.buyer.tel || "--" }}
                          </p>
                          <p
                            class="ellipsisText "
                            :title="
                              `${bankAccInfo.buyer.bank || '--'} / ${bankAccInfo.buyer.account ||
                                '--'}`
                            "
                          >
                            {{
                              `${bankAccInfo.buyer.bank || "--"} / ${bankAccInfo.buyer.account ||
                                "--"}`
                            }}
                          </p>
                        </div>
                      </div>
                      <i class="el-icon-edit" @click="editBuyer"></i>
                    </div>
                  </div>
                </div>
              </div>
            </el-col>
            <el-col :span="12">
              <fw-title titles="销售单位" style="padding-top: 15px" />
              <div class="bar">
                <div class="bar_col" style="align-items: flex-start;">
                  <div class="divs">
                    销售单位
                  </div>
                  <div>
                    <div class="choseGongji">
                      <h4 class="ellipsisText " :title="bankAccInfo.seller.name || '--'">
                        {{ bankAccInfo.seller.name || "--" }}
                      </h4>
                      <div>
                        <div>
                          <p>纳税人识别号</p>
                          <p>地址</p>
                          <p>电话</p>
                          <p>开户行及账号</p>
                        </div>
                        <div>
                          <p class="ellipsisText " :title="bankAccInfo.seller.code || '--'">
                            {{ bankAccInfo.seller.code || "--" }}
                          </p>
                          <p class="ellipsisText " :title="bankAccInfo.seller.address || '--'">
                            {{ bankAccInfo.seller.address || "--" }}
                          </p>
                          <p class="ellipsisText " :title="bankAccInfo.seller.tel || '--'">
                            {{ bankAccInfo.seller.tel || "--" }}
                          </p>
                          <p
                            class="ellipsisText "
                            :title="
                              `${bankAccInfo.seller.bank || '--'} / ${bankAccInfo.seller.account ||
                                '--'}`
                            "
                          >
                            {{
                              `${bankAccInfo.seller.bank || "--"} / ${bankAccInfo.seller.account ||
                                "--"}`
                            }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
          <el-row>
            <fw-title titles="联系地址" style="padding-top: 15px" />
            <el-col :span="18" style="margin-top:15px" v-if="showAddTable">
              <vxe-table
                style="min-height:10vh"
                class="mytable-scrollbar"
                stripe
                highlight-hover-row
                size="small"
                :border="false"
                ref="xTable1"
                id="toolbar_demo3"
                :data="addressArr"
                row-key
                auto-resize
              >
                <vxe-column min-width="180" fixed="left">
                  <template #header>
                    <p>类型</p>
                  </template>
                  <template #default="{ row }">
                    <p class="ellipsisText" :title="row.type || '--'">
                      {{ row.type || "--" }}
                    </p>
                  </template>
                </vxe-column>
                <vxe-column min-width="140">
                  <template #header>
                    <p>
                      <!-- <span style="color:red">*</span> -->
                      联系人
                    </p>
                  </template>
                  <template #default="{row}">
                    <template v-if="row.is_input">
                      <el-input placeholder="请输入" size="small" v-model="row.name"></el-input>
                    </template>
                    <template v-else>
                      {{ row.name || "--" }}
                    </template>
                  </template>
                </vxe-column>
                <vxe-column min-width="260">
                  <template #header>
                    <p>
                      <!-- <span style="color:red">*</span> -->
                      联系地址
                    </p>
                  </template>
                  <template #default="{row}">
                    <template v-if="row.is_input">
                      <fw-map
                        :change="true"
                        :address="row.address"
                        :onlyAddress="false"
                        :showInput="true"
                        @mapAddress="val => mapAddress(row, val)"
                      />
                    </template>
                    <template v-else>
                      {{ renderAddress(row.address) }}
                    </template>
                  </template>
                </vxe-column>
                <vxe-column min-width="140">
                  <template #header>
                    <p>
                      <!-- <span style="color:red">*</span> -->
                      邮箱
                    </p>
                  </template>
                  <template #default="{row}">
                    <template v-if="row.is_input">
                      <el-input placeholder="请输入" size="small" v-model="row.email"></el-input>
                    </template>
                    <template v-else>
                      {{ row.email || "--" }}
                    </template>
                  </template>
                </vxe-column>
                <vxe-column title="操作" width="140">
                  <template #default="{row,rowIndex}">
                    <div v-if="row.is_input" class="act-btn-box">
                      <el-link
                        :underline="false"
                        class="act-btn"
                        type="primary"
                        @click="confirmBtn(row, rowIndex)"
                      >
                        确定
                      </el-link>
                      <el-link
                        :underline="false"
                        class="act-btn"
                        type="primary"
                        @click="cancelBtn(row, rowIndex)"
                      >
                        取消
                      </el-link>
                    </div>
                    <div v-else class="act-btn-box">
                      <el-link
                        :underline="false"
                        class="act-btn"
                        type="primary"
                        @click="editBtn(row)"
                      >
                        编辑
                      </el-link>
                    </div>
                  </template>
                </vxe-column>
              </vxe-table>
            </el-col>
          </el-row>
          <div>
            <!-- 订单明细-->
            <orderDetailTable :tableList="tableList" />
          </div>
        </div>
      </el-main>
      <el-footer style="margin: 30px 0; height: auto">
        <el-row>
          <el-col align="center" :span="24">
            <el-button style="width: 88px" size="small" @click="$router.go(-1)">取消</el-button>
            <el-button style="width: 88px" size="small" type="primary" @click="submit">
              提交
            </el-button>
          </el-col>
        </el-row>
      </el-footer>
    </el-container>
    <fw-choseticket ref="fwchoseticket" />
    <!-- 编辑购货单位 -->
    <editBox ref="editBox" />
  </div>
</template>

<script>
import Basmsg from "./components/basmsg.vue";
import editBox from "./components/editBox.vue";
import orderDetailTable from "./components/orderDetailTable.vue";
export default {
  name: "",
  components: {
    Basmsg,
    orderDetailTable,
    editBox,
  },
  props: {},
  created() {},
  mounted() {
    this.getBasicInfo();
  },
  data() {
    return {
      dataInfo: null,
      addressArr: [
        {
          type: "收票人",
          name: "", //联系人名称
          address: {
            province: "",
            city: "",
            area: "",
            address: "",
            lat: "",
            lng: "",
          }, //联系人地址
          email: "", //邮箱
          is_input: true,
        },
        {
          type: "寄票人",
          name: "", //联系人名称
          address: {
            province: "",
            city: "",
            area: "",
            address: "",
            lat: "",
            lng: "",
          }, //联系人地址
          email: "", //邮箱
          is_input: true,
        },
      ], //联系人地址

      tableList: [],
      showAddTable: true,
      bankAccInfo: {
        buyer: {
          name: "",
          code: "",
          address: "",
          tel: "",
          account: "",
          bank: "",
        },
        seller: {
          name: "",
          code: "",
          address: "",
          tel: "",
          account: "",
          bank: "",
        },
      },
    };
  },
  watch: {},
  computed: {
    renderAddress() {
      return obj => {
        if (obj && obj.province) {
          let msg = obj.province + obj.city + obj.area + obj.address;
          return msg;
        } else {
          return "--";
        }
      };
    },
    totalPrice() {
      let sum = this.invoiceTable.reduce(function(total, item) {
        return total + Number(item.total_amount1);
      }, 0);
      return sum;
    },
  },
  methods: {
    getAccInfo() {
      this.$axios
        .postJSON(`/ocr/invoice_title/layer`, { company_id: this.dataInfo.seller_company_id })
        .then(res => {
          if (res.code == 200) {
            if (res.data.list) {
              let data = res.data.list[0];
              this.bankAccInfo.seller.address = data.address
              this.bankAccInfo.seller.tel = data.tel
              this.bankAccInfo.seller.account = data.account
              this.bankAccInfo.seller.bank = data.bank
            }
          }
        });
    },
    editBuyer() {
      let buyer = this.bankAccInfo.buyer;
      this.$refs.editBox.open({ buyer }, val => {
        console.log(val);
        this.bankAccInfo.buyer = JSON.parse(JSON.stringify(val));
      });
    },
    getBasicInfo() {
      const obj = JSON.parse(sessionStorage.getItem("invoicingIdsMyMy"));
      let ids = obj.ids;
      this.$axios.postJSON(`/shop/public/common/personal/list`, { id: ids }).then(res => {
        if (res.data) {
          console.log(res.data);
          let data = res.data.data;
          let not_invoice_amount = data.reduce(
            (sum, product) => sum + Number(product.not_invoice_amount),
            0
          );
          let not_invoiced_amount = data.reduce(
            (sum, product) => sum + Number(product.not_invoiced_amount),
            0
          );
          let order_amount = data.reduce((sum, product) => sum + Number(product.invoice_amount), 0);
          let obj = {
            buyer_company_name: data[0].buyer_company_name,
            buyer_company_code: data[0].buyer_company_code,
            buyer_company_id: data[0].buyer_company_id,
            seller_company_name: data[0].seller_company_name,
            seller_company_code: data[0].seller_company_code,
            seller_company_id: data[0].seller_company_id,
            not_invoice_amount: not_invoice_amount, //可开票金额
            not_invoiced_amount: not_invoiced_amount, //未收票金额
            order_amount: order_amount, //累计开票金额
            apply_serial_number: data[0].order_invoice?.apply_serial_number,
          };
          this.dataInfo = obj;
          this.bankAccInfo.buyer.name = data[0].buyer_company_name;
          this.bankAccInfo.buyer.code = data[0].buyer_company_code;
          this.bankAccInfo.buyer.tel = data[0].buyer_company_code;
          this.bankAccInfo.seller.name = data[0].seller_company_name;
          this.bankAccInfo.seller.code = data[0].seller_company_code;
          this.tableList = data;
          if (this.$route.query.order_invoice_id) {
            if (data[0].order_invoice && data[0].order_invoice.buyer_info) {
              this.bankAccInfo.buyer = data[0].order_invoice?.buyer_info;
            }
            if (data[0].order_invoice && data[0].order_invoice.seller_info) {
              this.bankAccInfo.seller = data[0].order_invoice?.seller_info;
            }
          } else {
            this.getAccInfo();
          }
          if (this.$route.query.order_invoice_id) {
            this.getAddressInfo();
          } else {
            this.addressArr[0] = {
              type: "收票人",
              is_input: false,
              ...data[0].order_invoice?.invoice_info,
            };
            this.addressArr[1] = {
              type: "寄票人",
              is_input: false,
              ...data[0].order_invoice?.sender_info,
            };
            console.log(this.addressArr, "addressArraddressArraddressArraddressArr");
            this.showAddTable = false;
            this.$nextTick(() => {
              this.showAddTable = true;
            });
          }
        }
      });
    },
    getAddressInfo() {
      let id = this.$route.query.order_invoice_id;
      this.$axios.get(`/shop/public/common/personal/sign_detail`, { id }).then(res => {
        if (res.data) {
          this.addressArr[0] = {
            type: "收票人",
            is_input: false,
            ...res.data.invoice_info,
          };
          this.addressArr[1] = {
            type: "寄票人",
            is_input: false,
            ...res.data.sender_info,
          };
          console.log(this.addressArr, "addressArraddressArraddressArraddressArr");
          this.showAddTable = false;
          this.$nextTick(() => {
            this.showAddTable = true;
          });
        }
      });
    },
    mapAddress(row, val) {
      console.log(row, val);
      let obj = JSON.parse(val);
      row.address = obj;
    },
    confirmBtn(row, index) {
      if (index == 0) {
        if (!row.name) {
          return this.$message.error("请输入收票联系人");
        }
        if (!row.address.lat) {
          return this.$message.error("请输入收票联系地址");
        }
        if (!row.email) {
          return this.$message.error("请输入收票邮箱");
        }
      }
      row.is_input = false;
    },
    editBtn(row) {
      row.is_input = true;
      let { name, address, email } = row;
      row.saveData = { name, address, email };
    },
    cancelBtn(row, index) {
      row.is_input = false;
      let { name, address, email } = row.saveData;
      row.name = name;
      row.address = address;
      row.email = email;
    },
    rendArr(arr) {
      let msg = null;
      if (arr && arr.length > 0) {
        msg = arr.map(v => v.origin_data_num).join(";");
      } else {
        msg = "--";
      }
      return msg;
    },

    submit() {
      let flag = this.addressArr.every(v => v.is_input == false);
      if (!flag) {
        return this.$message.error("请确认联系地址");
      }
      let invoice_info = this.addressArr[0]; //收票人信息
      let sender_info = this.addressArr[1]; //寄票人信息
      let buyer_info = this.bankAccInfo.buyer; 
      let seller_info = this.bankAccInfo.seller; 
      const obj = JSON.parse(sessionStorage.getItem("invoicingIdsMyMy"));
      let ids = obj.ids;
      let params = {
        order_ids: ids, //订单id
        invoice_info: invoice_info,
        sender_info: sender_info,
        buyer_info,
        seller_info,
      };
      let api = "";
      console.log(params);
      if (this.$route.query.order_invoice_id) {
        params.order_invoice_id = Number(this.$route.query.order_invoice_id);
        api = `/shop/public/common/personal/update_apply`;
        delete params.order_ids;

        this.$axios.putJSON(api, params).then(res => {
          if (res.code == 200) {
            this.$message.success("提交成功");
            setTimeout(() => {
              this.$router.push("/myOrder");
            }, 800);
          }
        });
      } else {
        api = `/shop/public/common/personal/apply`;
        this.$axios.postJSON(api, params).then(res => {
          if (res.code == 200) {
            this.$message.success("提交成功");
            setTimeout(() => {
              this.$router.push("/myOrder");
            }, 800);
          }
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
#main_MyOrder_Invoice {
  padding-bottom: 1px;
  box-sizing: border-box;
  height: 90vh;
  .zone {
    min-height: 10vh;
    height: auto;
  }
}
.head_info_bar {
  background-color: #fff;
  padding: 0 15px;
  margin: 10px 0;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}
.choseGongji {
  margin: 12px 12px 12px 0;
  position: relative;
  display: inline-block;
  width: 80%;
  margin-right: 3%;
  padding: 10px 20px;
  box-sizing: border-box;
  background-color: #fafafa;
  & > p {
    color: #0088fe;
    line-height: 30px !important;
  }
  p {
    line-height: 24px;
  }
  & > i {
    position: absolute;
    top: -5px;
    right: -5px;
    color: #ccc;
    cursor: pointer;
  }

  & > div {
    display: flex;
    & > div:nth-child(1) {
      width: 100px;
    }
    & > div:nth-child(2) {
      overflow: hidden;
      flex: 1;
      p {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
.bar {
  background-color: #fff;
  padding: 5px 15px;
  box-sizing: border-box;
  margin-bottom: 15px;
  .bar_col {
    display: flex;
    align-items: center;
    line-height: 35px;
    .divs {
      display: flex;
      align-items: center;
      min-width: 100px;
      i {
        margin: 0 5px;
      }
    }
    > div:last-child {
      flex: 1;
      word-wrap: break-word; /* 当单词太长时自动换行 */
      word-break: break-all; /* 使单词在任意字符内换行，避免单词被截断 */
    }
  }
}
.act-btn {
  margin-right: 5px;
  color: #66b1fc;
}
.buyer_box {
  position: relative;
  i {
    position: absolute;
    top: 2%;
    right: 2%;
    color: #0088fe;
    cursor: pointer;
  }
}
</style>
